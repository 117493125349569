import React, { useEffect } from 'react';
import { Button, Badge, Drawer, Box } from '@material-ui/core';
import {
  ShoppingCartOutlined,
  RemoveShoppingCartOutlined
} from '@material-ui/icons';
import styled from 'styled-components';
import LineItem from './LineItem';
import { useShopify } from '../hooks';

export default () => {
  const {
    cartCount,
    cartStatus,
    closeCart,
    openCart,
    checkoutState,
    setCount
  } = useShopify();

  function handleOpen(e) {
    if (e) e.preventDefault();
    openCart();
  }

  function handleClose(e) {
    e.preventDefault();
    closeCart();
  }

  function openCheckout(e) {
    e.preventDefault();

    window.location.assign(checkoutState.webUrl);
  }

  useEffect(() => {
    function getCount() {
      const lineItems =
        checkoutState.lineItems && checkoutState.lineItems.length > 0
          ? checkoutState.lineItems
          : [];
      let count = 0;
      lineItems.forEach((item) => {
        count += item.quantity;
        return count;
      });

      setCount(count);
    }

    getCount();
  }, [cartStatus, checkoutState]);

  return (
    <>
      <div className="App__view-cart-wrapper">
        {!cartStatus && (
          <StyledBadge badgeContent={cartCount} color="primary">
            <button className="App__view-cart" onClick={(e) => handleOpen(e)}>
              <ShoppingCartOutlined className="Cart__icon" />
            </button>
          </StyledBadge>
        )}
      </div>
      <Drawer
        anchor="right"
        open={cartStatus}
        onClose={handleClose}
        onOpen={handleOpen}
        transitionDuration={100}
      >
        <div id="cart">
          <div className="Cart">
            <header className="Cart__header">
              <h2 className="Cart__title">Shopping Cart</h2>
              <button className="Cart__close" onClick={(e) => handleClose(e)}>
                <RemoveShoppingCartOutlined className="Cart__icon" />
              </button>
            </header>
            <div className="Cart__line-items">
              <LineItem />
            </div>
            <footer className="Cart__footer">
              {/* <div className="Cart-info clearfix">
            <div className="Cart-info__total Cart-info__small">Subtotal</div>
            <div className="Cart-info__pricing">
              <span className="pricing">$ {checkoutState.subtotalPrice}</span>
            </div>
          </div>
          <div className="Cart-info clearfix">
            <div className="Cart-info__total Cart-info__small">Taxes</div>
            <div className="Cart-info__pricing">
              <span className="pricing">$ {checkoutState.totalTax}</span>
            </div>
          </div>
          <div className="Cart-info clearfix">
            <div className="Cart-info__total Cart-info__small">Total</div>
            <div className="Cart-info__pricing">
              <span className="pricing">$ {checkoutState.totalPrice}</span>
            </div>
          </div> */}
              <Box display={{ xs: 'block', sm: 'none' }}>
                <StyledButton
                  className="Cart__checkout"
                  onClick={handleClose}
                  size="large"
                  variant="contained"
                >
                  Add Something Else
                </StyledButton>
              </Box>
              <StyledButton
                disabled={cartCount < 1}
                className="Cart__checkout "
                onClick={(e) => openCheckout(e)}
                size="large"
                variant="contained"
                color="primary"
              >
                Checkout
              </StyledButton>
            </footer>
          </div>
        </div>
      </Drawer>
      {cartCount > 0 && !cartStatus && (
        <div className="App__view-cart-wrapper-mobile">
          <StyledButton
            size="large"
            variant="contained"
            color="primary"
            className="App__view-cart-mobile"
            onClick={(e) => handleOpen(e)}
          >
            {`View Cart (${cartCount} items)`}
          </StyledButton>
        </div>
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  border-radius: 0;

  .MuiButton-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 28px;

    @media (min-width: 960px) {
      font-size: 14px;
    }

    @media (min-width: 1280px) {
      font-size: 18px;
    }
  }
`;

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    font-size: 1.2rem;
    background-color: #FEEA51;
  }

  .MuiBadge-anchorOriginTopRightRectangle {
    top: 5px;
    right: 10px;
  }
`;
