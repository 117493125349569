import React, { useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import Home from './Home';
import { useShopify } from '../hooks';
import { useCollection } from '../context/collection';
import createTheme from '../theme/material-ui';
import NotFound from './NotFound';
import ProductLanding from './ProductLanding';

const App = () => {
  const { createShop, createCheckout, fetchCollection } = useShopify();
  const { shopifyID, accentColor, accentColorLuminance } = useCollection();

  const theme = useMemo(() => createTheme(accentColor, accentColorLuminance), [
    accentColor,
    accentColorLuminance
  ]);

  useEffect(() => {
    createShop();
    createCheckout();
    fetchCollection(shopifyID);
  }, [shopifyID]);

  return (
    <ThemeProvider theme={theme}>
      <div id="App">
      <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/products" component={Home} />
            <Route exact path="/products/:id" component={ProductLanding} />
						<Route exact path="/latest" component={Home} />
            <Route exact path="*" component={NotFound} />
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
};


export default App;